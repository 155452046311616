<template>
  <div class="v-stack h-stretch gap-3" v-if="client">
    <a class="heading-title-2">{{ client.name }}</a>
    <a class="heading-title-1">Info</a>
    <div>ICO: {{ client.ico }}</div>
    <a :href="client.website" target="_blank">{{ client.website }}</a>
    <div class="card light mn">
      <div class="content">
        <div class="v-stack gap-3">
          <div class="pane-horizontal gap-3">
            <label class="text-right">Name:</label>
            <div class="text-left">{{ client.contactName || "Not Set" }}</div>
          </div>
          <div class="pane-horizontal gap-3">
            <label class="text-right">Phone:</label>
            <div class="text-left">{{ client.contactPhone || "Not Set" }}</div>
          </div>
          <div class="pane-horizontal gap-3">
            <label class="text-right">Email:</label>
            <div class="text-left">{{ client.contactEmail || "Not Set" }}</div>
          </div>
        </div>
      </div>
    </div>
    <textarea
      placeholder="description"
      v-model="client.description"
      disabled
    ></textarea>
    <div class="h-stack h-end">
      <button
        @click="$router.push('/clients/edit/' + id)"
        class="flat dynamic md error"
      >
        Edit
      </button>
    </div>
    <a class="heading-title-1">Projects</a>
    <div class="h-stack h-end">
      <button
        @click="$router.push('/projects/add?client=' + client._id)"
        class="add"
      >
        New
      </button>
    </div>
    <Table
      :items="client.projects"
      :defaultColumn="1"
      v-on:rowSelected="$router.push('/projects/detail/' + $event._id)"
      css="60px 60px 1fr 80px 80px 200px"
      :columns="[
        {
          name: 'State',
          sort: 'alphabet',
          path: ['state'],
        },
        {
          name: 'Icon',
          sort: 'alphabet',
          path: ['icon'],
        },
        {
          name: 'Project',
          sort: 'alphabet',
          path: ['name'],
        },
        {
          name: 'Created',
          sort: 'date',
          path: ['createdDate'],
          formatType: 'date',
          format: 'DD.MM.YYYY',
        },
        {
          name: 'Deadline',
          sort: 'date',
          path: ['deadline'],
          formatType: 'date',
          format: 'DD.MM.YYYY',
        },
        {
          name: 'Vimeo',
          sort: 'alphabet',
          path: ['vimeo'],
          formatType: 'link',
        },
      ]"
    >
    </Table>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Table from "@/components/Table.vue";

export default {
  data() {
    return {
      client: null,
      users: [],
    };
  },
  props: ["id"],
  components: {
    Table,
  },
  methods: {
    ...mapActions(["getClient", "getUsersByClient"]),
  },
  mounted() {
    this.getClient(this.id)
      .then((client) => {
        this.client = client;
      })
      .catch((error) => {
        console.log(error);
      });

    this.getUsersByClient(this.id)
      .then((users) => {
        this.users = users;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>